<template>
    <v-card>
        <s-toolbar label="Configuracion de Lineas"
            dark 
            close 
            @close="close()"
            color="primary"
            
            @save="saveConfig()"
        >
        </s-toolbar>
        <!-- <v-card-title>Configuracion de Linea</v-card-title> -->
        <v-container>
            <v-row>
                <v-col sm="12" md="6">
                    <v-row>
                        <v-col>
                            <v-row >
                                <v-col sm="12" md="10">
                                    <v-card-text style="height: 40px; background: #ecf5ff; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;">
                                        <h3>Agregar responsable de la Linea</h3>
                                    </v-card-text>
                                </v-col>
                                <v-col>
                                    <v-btn rounded fab x-small @click="addItemGP()">
                                        <i class="fas fa-plus"></i>
                                    </v-btn>   
                                </v-col>
                            </v-row>   
                            <v-row >                
                                <v-col cols="3" lg="3" md="3" sm="12">		
                                    <s-toolbar-person
                                        v-model="line.PrlDocumentNumer"
                                        @returnPerson="hadReturnPerson($event)"
                                        ref="focusPrsDocument"
                                        noCamera
                                        noEdit
                                    />					
                                </v-col>
                                <v-col cols="4" lg="4" md="4" sm="12">
                                    <s-text
                                        label="Nombres"
                                        v-model.trim="newPerson.PrlName"
                                        ref="focusPgdName"
                                    ></s-text>
                                </v-col>
                                <v-col cols="4" lg="4" md="4" sm="12">
                                    <s-text
                                        label="Apellidos"
                                        v-model.trim="newPerson.PrlLastName"
                                    ></s-text>
                                </v-col>
                                <v-col cols="4" lg="4" md="4" sm="12" hidden>
                                    <s-text
                                        label="DNI"
                                        v-model.trim="newPerson.PrlDocumentNumer"
                                    ></s-text>
                                </v-col>
                            </v-row>
                            <v-row class="pt-3 pr-3 pl-3">
                                <v-col class="pl-0 pt-0">
                                    <v-data-table
                                        dense
                                        :headers="headerResponsable"
                                        :items="itemsDataPG"
                                        hide-default-footer
                                        :items-per-page="-1"
                                        disable-sort
                                    >
                                        <template v-slot:item.Actions="{ item }">
                                            <v-btn
                                                @click="clickDeleteItemGP(item)"
                                                x-small
                                                fab
                                                color="error"
                                            >
                                            <v-icon style="font-size: 16px !important"
                                            >mdi-close</v-icon
                                            >
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12" lg="12" class="s-col-form">
                                    <v-btn small block left color="primary" dark @click="savePersonResponsibleLine()" hide-details>
                                        <v-icon left small>fas fa-save</v-icon> Guardar
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-row >
                                <v-col sm="12" md="12">
                                    <v-card-text style="height: 40px; background: #ecf5ff; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;">
                                        <h3>Seleccionar Responsable</h3>
                                    </v-card-text>
                                </v-col>
                            </v-row>
                            <v-row class="pt-3 pr-3 pl-3">
                                <v-col>
                                    <v-data-table
                                        v-model="SelecPersonPermisions"
                                        dense 
                                        :headers="HeaderPersonPermisions"
                                        :items="ItemPersonPermisions"
                                        :items-per-page="5"
                                        disable-sort 
                                        hide-default-footer
                                        item-key="PrlID" 
                                        show-select
                                        single-select
                                    >
                                    <!-- show-select -->
                                        <!-- @click:row="rowClickPerson" -->
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    
                </v-col>
              
                <v-divider vertical></v-divider>
                <v-col sm="12" md="3">
                    <v-row >
                        <v-col sm="12" md="12">
                            <v-card-text style="height: 40px; background: #ecf5ff; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;">
                                <h3>Permiso Encartonado</h3>
                            </v-card-text>
                        </v-col>
                    </v-row>   
                    <v-row>
                        <v-col v-if="showCard">
                            <v-data-table
                                v-model="SelectLine"
                                dense 
                                :headers="HeaderLine"
                                :items="ItemLine"
                                :items-per-page="-1"
                                disable-sort 
                                hide-default-footer
                                item-key="Line" 
                                show-select 
                            >
                        

                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col>

                <v-divider vertical></v-divider>
                <v-col sm="12" md="3">
                    <v-row >
                        <v-col sm="12" md="12">
                            <v-card-text style="height: 40px; background: #ecf5ff; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;">
                                <h3>MTV</h3>
                            </v-card-text>
                        </v-col>
                    </v-row>   
                    <v-row>
                        <v-col v-if="showCardMtv">
                            <v-data-table
                                v-model="SelectMtv"
                                dense 
                                :headers="HeaderMtv"
                                :items="ItemMtv"
                                :items-per-page="-1"
                                disable-sort 
                                hide-default-footer
                                item-key="PbpID" 
                                show-select 
                            >
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-row>
                                 <v-col>
                                    <v-card-text style="height: 40px; background: #ecf5ff; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;">
                                        <h3>MTV Asignado</h3>
                                    </v-card-text>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col v-if="showCardMtv">
                                    <v-data-table
                                        
                                        dense 
                                        :headers="HeaderMtvAsig"
                                        :items="ItemMtvAsig"
                                        :items-per-page="-1"
                                        disable-sort 
                                        hide-default-footer
                                        item-key="PbpID" 
                                        
                                    >
                                        <template v-slot:item.Actions="{ item }">
                                            <v-btn
                                                @click="clickDelete(item)"
                                                fab dark x-small
                                                color="error" >
                                                <v-icon style="font-size: 16px !important"
                                                    >mdi-close</v-icon >
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                        
                                    <!-- v-model="SelectMtv"
                                    show-select  -->
                                </v-col>
                            </v-row>
                        </v-col>
                       
                        
                    </v-row>
                </v-col>
                
            </v-row>
            
            
        </v-container>
       
    </v-card>
</template>

<script>

import SToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
import _sFrzLinesPackingService from "@/services/FrozenProduction/FrzLinesPackingL.js"
// import _sFrzPackingLinesOutProcessService from "@/services/FrozenProduction/FrzPackingLinesOutProcessService.js"
import _sFrzPackingResponsibleLinesService from "@/services/FrozenProduction/FrzPackingResponsibleLines.js"

    export default {
        components:{SToolbarPerson},
        props: {
  
                  
            lineSelected:{
                default: null,
                type: Object,
            }
        },
        data(){
            return {
                newPerson: {},
                headerResponsable: [
                    { text:"item", value :"Line"},
                    { text: "Nombre", value: "PrlName"},
                    { text: "Apellidos", value: "PrlLastName"},
                    { text: "dni", value: "PrlDocumentNumer"},
                ],
                itemsDataPG:[],
                itemsDeletePG: [],
                line: {},
                item: {},
                SelectMtv: [],
                HeaderMtv:[
                    { text: "ID", value:"PbpID"},
                    { text: "Descripcion", value:"PsbDescription"},
                ],
                ItemMtv: [],
                SelectLine: [],
                HeaderLine:[
                    { text: "ID", value:"PlpId"},
                    { text: "Descripcion", value:"PloDescription"},
                ],
                ItemLine: [],
                SelecPersonPermisions: [],
                HeaderPersonPermisions:[
                    { text: "ID", value:"PrlID"},
                    { text: "Nombres", value:"PrlFullName"},
                    { text: "DNI", value:"PrlDocumentNumer"},
                ],
                ItemPersonPermisions: [],
                showCard: false,
                showCardMtv: false,
                HeaderMtvAsig:[
                    { text: "ID", value:"PbpID"},
                    { text: "Descripcion", value:"PsbDescription"},
                    { text: "Accion", value:"Actions"}
                ],
                ItemMtvAsig: [],
                PrlID : 0,
                
            }
        },
        methods: {
            hadReturnPerson(line){
           
                if (line != null) {
                    this.newPerson.PrlName = line.NtpName.trim();
                    this.newPerson.PrlLastName = line.NtpPaternalSurname.trim() +" "+ line.NtpMaternalSurname.trim();
                    this.newPerson.PrlDocumentNumer = line.PrsDocumentNumber;
                    

                    this.newPerson.PlpID = this.lineSelected.PlpID;
                    this.newPerson.UsrCreateID = this.$fun.getUserID();

                    this.newPerson.PrsID = line.PrsID;
                    this.newPerson.NtpID = line.NtpID;
                // this.newPerson.NtpName = line.NtpName;

                }
            },
            addItemGP(){
                let existPerson = false;
                this.itemsDataPG.forEach(element => {
                    if(element.PrlDocumentNumer == this.newPerson.PrlDocumentNumer){
                        existPerson = true;
                        console.log('trabajador existe ',existPerson)
                    }
                });
                if (existPerson){
                    this.$fun.alert("Trabajador ya Ingresado", "warning");
                    return ;
                }
                    if (!this.newPerson.PrlName) {
                    this.$refs.focusPgdName.error("Debe ingresar el nombre de un Responsable");
                    return;
                    } else {

                    this.newPerson.Line = this.itemsDataPG.length + 1;
                    this.newPerson.SecStatus = 1;
                    this.itemsDataPG.push({...this.newPerson});
                    //console.log("items agregados",this.newPerson)
                    this.newPerson = {};

                    this.PrlName = "";
                    this.$refs.focusPgdName.focus();
                    
                    
                }
            },
            clickDeleteItemGP(item) {
                this.itemsDataPG = this.itemsDataPG.filter(
                    (x) => {
                    return x.Line != item.Line
                    }
                );
                item.SecStatus = 0;
                if(item.PrlID > 0)
                {
                    this.itemsDeletePG.push({ ...item} ); 
                    console.log('eliminados',item );                   
                }
                
            },
            ListMtv()
            {
                let val = {
                    PlpID : this.lineSelected.PlpID,
                    PrlID : this.PrlID
                }
                console.log('valll',val)
                _sFrzLinesPackingService.lisMtv(val, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        this.ItemMtv = r.data;
                        this.SelectMtv=[]
                        console.log('Lst Mtv',this.ItemMtv);
                    }
                })
            },
            LisLineas(){
                console.log('Lista LINE',this.lineSelected);
                _sFrzLinesPackingService.lineID({PlpID : this.lineSelected.PlpID}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        this.ItemLine = r.data;
                    }
                })
            },
            close(){
                this.$emit("ClosedialogConfigLine")
            },
            savePersonResponsibleLine(){
                if(this.itemsDataPG.length <= 0 ){
                    this.$fun.alert("Grupo debe tener Integrantes", "warning");
                    this.$refs.focusPrsDocument.focus();
                    return;
                }

                console.log("eliminados", this.itemsDeletePG)
                this.itemsDataPGAdd = [...this.itemsDataPG, ...this.itemsDeletePG];

                let item = {}
                item.FrzPackingResponsibleLinesDetail = this.itemsDataPGAdd;
                item.FrzPackingResponsibleLinesDetail.forEach(element => {
                    element.PrlID = 0;
                    element.UsrCreateID = this.$fun.getUserID();
                    element.UsrUpdateID = this.$fun.getUserID();
                    // element.PlpID = this.lineSelected.PlpID;
                });

                console.log('a guardar',item);

                this.$fun.alert("Estas seguro de guardar?", "question").then((r) => {
                    if(r.value){
                        _sFrzPackingResponsibleLinesService.saveResponsablesLine(item, this.$fun.getUserID()).then((r) => {
                        if (r.status == 200) {
                            this.$fun.alert("Registrado correctamente", "success");
                            this.$emit("close");
                            this.itemsDataPG = []
                            this.ListPersonLine()
                        }
                        });
                    }
                })
            },
            ListPersonLine(){
                console.log('List person by line',this.lineSelected.PlpID);
                _sFrzPackingResponsibleLinesService.listPersonLine({PlpID : this.lineSelected.PlpID}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        this.ItemPersonPermisions = r.data;
                    }
                })
                
            },
            
            sendResponsibleLine(valueNew, valueold)
            {
                
                if(this.ItemPersonPermisions.length > 0)
                {
                    if(valueold.length > 0)
                    {
                        console.log('eliminar',valueold[0].PrlID)
                        this.showCard = false;
                        this.SelectLine=[]
                        
                    }
                    if(valueNew.length > 0)
                    {
                        console.log('agregar',valueNew[0].PrlID)     
                        this.showCard = true;
                    }            
                }

            },
            sendMtvPerson(valueNew, valueold)
            {
                
                if(this.ItemMtv.length > 0)
                {
                    if(valueold.length > 0)
                    {
                        console.log('eliminar',this.SelecPersonPermisions)
                        
                        
                    }
                    if(valueNew.length > 0)
                    {   
                         console.log('agregar',this.SelecPersonPermisions);
                         
                         let item = {
                            PrlID : this.SelecPersonPermisions[0].PrlID,
                            PbpID : this.SelectMtv[0].PbpID,
                            PlpID : this.SelecPersonPermisions[0].PlpID,
                            UsrCreateID : this.$fun.getUserID(),
                            NtpID : this.SelecPersonPermisions[0].NtpID,

                        }
                        console.log('send Mtv',item);
                        // return
                        _sFrzPackingResponsibleLinesService.saveMtvPermisions(item, this.$fun.getUserID()).then((r) => {
                            if (r.status == 200) {
                                this.$fun.alert("Registrado correctamente", "success");
                                // this.itemsDataPG = []
                                this.ListMtvAsig();
                                this.ListMtv();
                            }
                        });
                        return
                        // console.log('agregar',valueNew[0].PrlID)     

                    }            
                }

            },
            sendLinePerson(valueNew, valueold)
            {
                
                if(this.ItemLine.length > 0)
                {
                    if(valueold.length > 0)
                    {
                        console.log('eliminar',this.SelecPersonPermisions)
                        this.showCardMtv = false;
                        
                    }
                    if(valueNew.length > 0)
                    {   
                       
                        /////
                        console.log('agregar',this.SelecPersonPermisions);
                        this.showCardMtv=true;
                        this.ListMtvAsig();
                        
                        this.PrlID = this.SelecPersonPermisions[0].PrlID
                        console.log('PrlID->',this.PrlID)
                        this.ListMtv();
                        
                        console.log(':(');
                        let item = {
                            PrlID : this.SelecPersonPermisions[0].PrlID,
                            PlpID : this.SelecPersonPermisions[0].PlpID,
                            UsrCreateID : this.$fun.getUserID(),
                            NtpID : this.SelecPersonPermisions[0].NtpID,
                        }
                        // console.log('Send Line',item);
                        // return
                        _sFrzPackingResponsibleLinesService.saveMtvPermisions(item, this.$fun.getUserID()).then((r) => {
                            if (r.status == 200) {
                                // this.$fun.alert("Registrado correctamente", "success");
                                // this.itemsDataPG = []
                                
                            }
                        });
                        
                        // console.log('agregar',valueNew[0].PrlID)     
                        return

                    }            
                }

            },
            ListMtvAsig()
            {
                console.log('Lista Mtv',this.lineSelected);
                let item = {
                    PrlID : this.SelecPersonPermisions[0].PrlID, //32
                    PlpID : this.lineSelected.PlpID
                }
                console.log('item',item);
                _sFrzPackingResponsibleLinesService.lisMtvAsig(item, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        this.ItemMtvAsig = r.data;
                        console.log('ItemMtvAsig',this.ItemMtvAsig);
                    }
                })
            },
            clickDelete(item){
                console.log('i',item.RldID);
                let val = {
                    RldID : item.RldID,
                    UsrUpdateID :  this.$fun.getUserID(),
                }
                this.$fun.alert("Estas seguro de ELiminar?", "question").then((r) => {
                    if(r.value){
                        _sFrzPackingResponsibleLinesService.dltPermisoMtv(val, this.$fun.getUserID()).then((r) => {
                        if (r.status == 200) {
                            this.$fun.alert("Eliminado correctamente", "success");
                            this.ListMtv();
                            this.ListMtvAsig();
                            // this.listResponsables();
                        }
                        });
                    }
                })

            }
            // rowClickPerson: function(item, row) {
			// 	this.selected = [item];
			// 	// console.log("item cargados del item", item);
			// 	console.log('items del selected', this.selected);
            //     // if(this.selected.length > 0) {

                    
            //     //     _sFrzPackingArmedPallet.GetQr({PapID : this.selected[0].PapID}, this.$fun.getUserID()).then(resp => {
            //     //         if (resp.status == 200) {
            //     //             this.selected = resp.data;
            //     //             console.log("FFFF",this.selected);
            //     //         }
            //     //     });
                   
                    
            //     // }
			// },

        },
        created(){
            this.ListMtv();
            this.LisLineas();
            this.ListPersonLine();
        },
        watch:{
            SelecPersonPermisions(valueNew, valueold){               
                this.sendResponsibleLine(valueNew, valueold);
                // console.log("valueNew ", valueNew, "valueold", valueold);
            },
            SelectMtv(valueNew, valueold){               
                this.sendMtvPerson(valueNew, valueold);
            },
            SelectLine(valueNew, valueold){               
                this.sendLinePerson(valueNew, valueold);
            },
          
            
        }
    }
</script>