<template>
    <div class="col-md-12 col-sm-12">
        <v-row>
            <v-col cols="1" class="mt-5">
                <v-btn v-model="showCard"
                    v-if="$fun.isAdmin()"
                    fab
                    x-small
                    color="success"
                    depressed
                    @click="showCard = !showCard" >
                    <v-icon >fas fa-plus</v-icon>
                </v-btn>
            </v-col>
            <v-col v-if="showCard">
                <v-card>
                    <v-card-text>
                        <v-row justify="space-around" no-gutters>
                            <v-col class="ml-1">
                                <s-select
                                    label="Turno de Produccion"
                                    :items="itemTurn"
                                    item-value="TrnID"
                                    item-text="TnrObservation"
                                    v-model="processInitial.TrnID">

                                </s-select>
                            </v-col>
                            <v-col class="ml-2">
                                <s-select
                                    label="Selladoras"
                                    :items="itemsLines"
                                    item-value="PloID"
                                    item-text="PloDescription"
                                    v-model="processInitial.PloID">

                                </s-select>

                            </v-col>
                            <v-col class="ml-2">
                                <s-select
                                    label="Grupo"
                                    :items="itemsGroup"
                                    item-value="PggID"
                                    item-text="PggNameGroup"
                                    v-model="processInitial.PggID">

                                </s-select>
                            </v-col>
                            <v-col cols="auto" class="ml-1 mt-2">
                                <v-btn
                                    color="primary"
                                    outlined 
                                    depressed
                                    @click="initialProcess()" >
                                    <v-icon left>mdi-content-save</v-icon>
                                    Agregar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>            
        </v-row>
        <br>
        <v-row>
            <v-col>
                <section>
                    <v-container class="container" px-5>
                        <v-row no-gutters v-for="(selladora, index) in linesOutProcess" :key="index">
                            <div  class="d-flex align-center mb-6 bg-surface-variant" style="width: 5rem;">                               
                                <v-btn 
                                   
                                    elevation="2"
                                    depressed                                    
                                    fab                                    
                                    bottom
                                    left
                                    @click="showdialogoutputcardboard(selladora)"
                                    >
                                    <v-icon class="mdi-36px">mdi-barcode-scan</v-icon>
                                </v-btn>
                            </div>
                            
                            <div class="card rounded-xl" style="width: 30rem; height: 10rem;">
                               
                                <div class="d-flex align-start mb-0 bg-surface-variant">
                                    <div class="ma-1 pa-1 me-auto">
                                        <h3 >{{selladora.PloDescription}}</h3>
                                    </div>
                                    <div class="ma-0 pa-0">
                                        <v-btn fab
                                            v-if="$fun.isAdmin()"
                                            x-small                                        
                                            depressed
                                            @click="ShowDialogOptions(selladora)">
                                            <v-icon  class="mdi-24px">mdi-dots-vertical-circle</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                                <br>
                                <div class="d-flex justify-space-evenly mb-6 bg-surface-variant">    
                                    <div class="ma-1 pa-2 me-auto" >
                                        <v-badge  color="success" v-if="$fun.isAdmin()">
                                            <template v-slot:badge>
                                                <span>{{selladora.numberPerson}}</span>
                                            </template>
                                            <v-icon  
                                                v-if="$fun.isAdmin()"
                                                class="mdi-24px mdi-dark"
                                                @click="ShowGroud(selladora)">mdi-account-check</v-icon>
                                        </v-badge>
                                    </div>
                                    <div class="ma-0 pa-0 me-auto">
                                        <v-btn 
                                            v-if="$fun.isAdmin()"
                                            color="warning"
                                            elevation="2"
                                            fab
                                            x-small                                        
                                            depressed
                                            @click="StopLine(selladora)">
                                            <v-icon class="mdi-36px">mdi-pause-circle-outline</v-icon>
                                        </v-btn>
                                    </div>                                                                                                                                       
                                </div>                                
                           </div>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn 
                                    color="#6a73d8"
                                    size="x-large"
                                    flat   
                                    @click="OpenDialogReempaque()"                                 
                                    >
                                    Reempaque
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                    
                </section>
            </v-col>
        </v-row>
        <v-dialog v-model="ShowGroupListPerson"
            v-if="ShowGroupListPerson" width="auto">
            <group-lines-out
                :SelectLine="SelectLine" 
                @close="UpdateLines()"
                :linesOutProcess="linesOutProcess"
                :TrnID="TrnID"
                ></group-lines-out>
        </v-dialog>
        <v-dialog v-model="dialogBagSealed"
            v-if="dialogBagSealed" width="500px">
                <template>
                    <v-card>
                        <s-toolbar label="Envio de bolsas Selladas a Encartonado"
                            dark 
                            close 
                            @close="dialogBagSealed = false"
                            color="primary">
                        </s-toolbar>
                        <v-card-title>Selladora</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>                            
                                    <!-- <s-text
                                        label="Cantidad de Bolsas selladas"
                                        decimal
                                        v-model="itemSealed.countsealedbags"></s-text> -->
                                    <v-data-table
                                        v-model="SelectBagsProcess"
                                        dense 
                                        :headers="HeaderBagsProcess"
                                        :items="BagsProcess"
                                        :items-per-page="-1"
                                        disable-sort 
                                        hide-default-footer
                                        item-key="Line" 
                                        show-select >
                                        <template v-slot:item.countsealedbags=" row ">
                                            <s-text v-model="row.item.countsealedbags" decimal ></s-text>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <!-- <v-row>
                                <v-col>                            
                                    <s-text
                                        label="Bolsas retornadas" 
                                        decimal
                                        v-model="itemSealed.returnbags"></s-text>
                                </v-col>
                            </v-row> -->
                        </v-card-text>
                        <v-card-actions>
                            <v-btn 
                                color="primary"
                                @click="SaveBagSealed()">
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
        </v-dialog>
        <v-dialog v-model="dialogBagSealedDetail" 
            v-if="dialogBagSealedDetail"
            fullscreen
            >
                <sealed-detail :SelectLine="SelectLine" @close="dialogBagSealedDetail = false"></sealed-detail>
        </v-dialog>

        <v-dialog 
            v-model="dialogConfigLine" 
            v-if="dialogConfigLine"
            fullscreen
            persistent
        >
                <config-lines :lineSelected="lineSelected" @ClosedialogConfigLine="ClosedialogConfigLine()"></config-lines>
        </v-dialog>   
        <v-dialog 
            v-model="dialogReempaque"
            v-if="dialogReempaque"
            fullscreen
            persistent>
            <view-reempaque :itemTurn="itemTurn[0]" @close="dialogReempaque = false"></view-reempaque>
        </v-dialog>
       
    </div>
</template>


<script>
    // import showLinesProcessDetail from "./showLinesProcessDetail.vue";
    import _sFrzLinesPackingService from "@/services/FrozenProduction/FrzLinesPackingL.js" // LISTA LAS LIENAS EN PROCESO
    import cLinesPacking from "./CLinesPackingL.vue"
    
    import _sTurnService from "@/services/FreshProduction/PrfTurnService";
    import FrzLinesPackingOut from "@/services/FrozenProduction/FrzLinesPackingOut.js"
    import _PackingGroup from "@/services/FrozenProduction/FrzPackingGroupService.js"; 
    import GroupLinesOut from "./GroupLinesOutProcess.vue"

    import _sendsealedbags from "@/services/FrozenProduction/FrzPackingSealedBags.js"; 
    import SealedDetail from "./FrzPackingBagSealedDetail.vue";
    import ConfigLines from "./FrzPackingConfigLines.vue";
    import ViewReempaque from "../FrzPackingLinesInProcess/ViewReempaque.vue";

    export default {
    components: {cLinesPacking, GroupLinesOut, SealedDetail,ConfigLines, ViewReempaque},
    prop: {
      
    },
    data: () => ({
        itemsLineas:[],
        processInitial: {
            PlpdateProcess: null,
            TypeTurn: null,
            TypeCultive: null
        },
        // btnInitial: false,
        // showLinesProcessDetail: false,
       
       //nuevo
       
        showCard: false,
        itemTurn: [],
        SelectLine: { },
        ShowGroupListPerson:false,
        ShowDialogArmedPalet:false,
        TrnID: 0,
        linesOutProcess: [],
        dialogBagSealed: false,

        SelectSealed:{},
        dialogBagSealedDetail: false,
        itemSealed:{},

        HeaderBagsProcess:[
            { text: "item", value:"Line"},
            { text: "Total de Bolsas", value:"NumberOfBags"},
            { text: "Bolsas a enviar", value:"countsealedbags"},
            { text: "item", value:"Line"},
            { text: "ID Trazabilidad", value:"TraceabilityID"},
        ],
        BagsProcess: [],
        SelectBagsProcess: [],
        dialogConfigLine: false,
        dialogReempaque: false,
    }),
    
    methods: {
        OpenDialogReempaque(){
            this.dialogReempaque = true;
            
        },
        TurnActive()
        {
            let TypeArea = 5
            _sTurnService.turnActive({TypeArea : TypeArea},this.$fun.getUserID()).then(r =>{
                if(r.status == 200)
                {                        
                    this.itemTurn = r.data;                       
                    this.LinesOutProcess();                        
                }
            });      
        },
        LinesOutProcess()
        {             
            // if(this.itemTurn)   
            // let NtpId = this.$fun.getUserInfo().NtpID;
            let NtpId = this.$fun.isAdmin() ? null : this.$fun.getUserInfo().NtpID;
            let data = {
                TrnID : this.itemTurn[0].TrnID,
                NtpId : NtpId,
            }
            
            _sFrzLinesPackingService.listLineas(data, this.$fun.getUserID())
            .then(r => {
                if(r.status == 200){
                    this.linesOutProcess = r.data;    
                    console.log('linesOutProcess',this.linesOutProcess);   
                }
            });
        },        
 
        initialProcess(){

            if(this.processInitial.TrnID == undefined){
                this.$fun.alert("Seleccion Turno de produccion", "info");
                return;
            }
            if(this.processInitial.PloID == undefined){
                this.$fun.alert("Seleccion Linea de salida", "info");
                return;
            }
            if(this.processInitial.PggID == undefined){
                this.$fun.alert("Seleccion grupo", "info");
                return;
            }  
            this.processInitial.UsrCreateID = this.$fun.getUserID();
            this.$fun.alert("Esta seguro de Iniciar Proceso?", "question").then((r) => {
                if (r.value) {
                    _sFrzLinesPackingService.initial(this.processInitial, this.$fun.getUserID())
                    .then((r) => {
                        
                        if (r.status == 200) 
                        {                 
                            this.$fun.alert("Se registro correctamente", "success");
                            this.TurnActive();
                        }
                    })
                }
            })
        },
        getLines()
        {
            FrzLinesPackingOut.list({}, this.$fun.getUserID()).then(r => {
                if(r.status == 200)
                {
                    this.itemsLines = r.data; 
                }
            })
        },
        getGroupSPacking()
        {
            _PackingGroup.list({TypeZoneWork:3}, this.$fun.getUserID()).then(r => {
                if(r.status == 200){
                    this.itemsGroup = r.data; 
                    console.log('itemsGroup',this.itemsGroup);
                }
            });
        },
        ShowGroud(line){
            this.ShowGroupListPerson = true;                
            this.SelectLine = line;
            this.TrnID = this.itemTurn[0].TrnID;
        },
        UpdateLines(){
            this.ShowGroupListPerson = false; 
            this.LinesOutProcess();
        },
        showdialogoutputcardboard(selladora){
            console.log('selladora',selladora);
            this.dialogBagSealedDetail = true;
            this.SelectLine = selladora;
            
            
        },
        SendBagSealed(Sealed)
        {
            this.SelectSealed = Sealed;
            this.dialogBagSealed = true;
            this.StockNumberBags(Sealed);
        },
        SaveBagSealed(){ 
            if(this.SelectBagsProcess.length == 0)
            {
                this.$fun.alert("Debe Selecionar Bolas a empaquetar", "warning");
                return;
            }
            
            this.$fun.alert("Esta seguro de Guardar ?", "question").then((r) => {
                if (r.value) {
                    
                    this.SelectBagsProcess.forEach(element => {
                        element.PlpId = this.SelectSealed.PlpId;
                        element.PbpID = this.SelectSealed.PbpID;
                    });
                    console.log("SelectBagsProcess-> ", this.SelectBagsProcess);
                    _sendsealedbags.save(this.SelectBagsProcess, this.$fun.getUserID()).then(r => {
                        if(r.status == 200)
                        {
                            this.$fun.alert("Se registro correctamente", "success");
                            this.dialogBagSealed = false;
                            this.SelectBagsProcess= [];
                            this.TurnActive();
                        }
                    })
                }
            })
        },
        StockNumberBags(Sealed)
        {
            _sFrzLinesPackingService.NumberBagsProcess(Sealed, this.$fun.getUserID()).then(r => {
                if(r.status == 200)
                {
                    r.data.forEach((element, index) => {
                            element.Line = index + 1;                            
                    });
                   

                    this.BagsProcess = r.data;
                }
            })
        },
        ShowDialogOptions(val){
            console.log('s',val);
            this.dialogConfigLine = true;
            this.lineSelected = val;
        },
        ClosedialogConfigLine(){
            this.dialogConfigLine = false
        },
    },
    created () {
		
        this.TurnActive();
        this.getGroupSPacking();
        this.getLines();
	},
    mounted(){
        
    }


  };
  </script>
  