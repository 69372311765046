import Service from "../Service";

const resource = "assistancelineoutprocess/";

export default {
    saveroll(prd, requestID){
        return Service.post(resource + "saveroll", prd,{
            params: {requestID: requestID},
        });
    },

    saveassistance(prd, requestID){
        return Service.post(resource + "saveassistance", prd,{
            params: {requestID: requestID},
        });
    },

    list(prd, requestID){
        return Service.post(resource + "list", prd,{
            params: {requestID: requestID},
        });
    },
    savetransfer(prd, requestID) {
        return Service.post(resource + "savetransfer", prd, {
            params: { requestID: requestID },
        });
    },
}