<template>
    <v-card>
        <s-toolbar label="Detalle de Bolsas selladas"
            dark 
            close 
            @close="close()"
            color="primary">
        </s-toolbar>
        <v-card-title>Listado de Sellado</v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <span class="text-h8">Numero Bolsas</span>
                </v-col>
                <v-col>
                    <span>
                        {{ this.TotalBags }}
                    </span>
                </v-col>
                <v-col>
                    <span class="text-h8">Peso Total</span>
                </v-col>
                <v-col>
                    <span>
                        {{ this.TotalBagWeight }}
                    </span>
                </v-col>
                <v-col>
                    <span class="text-h8">Total Cajas</span>
                </v-col>
                <v-col>
                    <span>
                        {{ this.TotalBox }}
                    </span>
                </v-col>
            </v-row>
            <v-row>
                <v-col>                            
                   <v-data-table 
                        v-model="selectedDetail"
                        :items="itemsBagSealedDetail"
                        dense 
                        :headers="HeaderBagSealedDetail"
                        :items-per-page="-1"
                        disable-sort 
                        hide-default-footer
                        item-key="PssID" 
                        show-select                        
                        
                        >
                        <template v-slot:item.bagutilizadas=" row ">
                            <s-text v-model="row.item.bagutilizadas" decimal @input="Calcular()"></s-text>
                        </template>
                        <template v-slot:item.Observation=" row ">
                            <s-textarea v-model="row.item.Observation"></s-textarea>
                        </template>
                        <template v-slot:item.TypeProduction=" row ">
                            <s-select-definition v-model="row.item.TypeProduction" label="T. Producción"
										:def="1464" @input="changeTypeProduction($event)"></s-select-definition>
                        </template>
                        <template v-slot:item.TypeVariant=" row ">
                            <s-select v-model="row.item.TypeVariant" 
                                label="Variante"
                                :items="ListVariante"
                                item-value="TsfID"
                                item-text="TsfDescription"
										></s-select>
                        </template>
                        <template v-slot:item.StateFinal=" row ">
                            <s-select v-model="row.item.StateFinal"
                                label="Estado Final"
                                item-value="TsfID"
                                item-text="TsfDescription"
								:items="ListEstadoFinal"></s-select>
                        </template>
                   </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn 
                color="primary"
                @click="btnSaveDetail()">
                Guardar
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

    import _sendsealedbags from "@/services/FrozenProduction/FrzPackingSealedBags.js"; 
    import _sFrzPackingArmedBox from "@/services/FrozenProduction/FrzPackingArmedBox.js";
    import SSelect from '../../../../components/Utils/SSelect.vue';
    import _typestatefinal from "@/services/FrozenProduction/FrzTypeStateFinal.js";  
    export default {
  components: { SSelect },
        watch:{
            selectedDetail(){
                this.Calcular();
            }
        },
        props: {
  
                  
            SelectLine:{
                default: null,
                type: Object,
            }
        },
        data(){
            return {
                itemsBagSealedDetail: [],
                selectedDetail: [],
                
                HeaderBagSealedDetail: [
                    { text: "item", value:"Line"},
                    { text: "Cliente", value:"CumNombre"},
                    { text: "Numero de Bolsas", value:"countsealedbags", width: 60},
                    { text: "Bolsas Utilizadas", value:"bagutilizadas", width: 60},
                    // { text: "Selladora", value:"PsbDescription"},
                    // { text: "Faja", value:"PloDescription"},
                    { text: "Faja", value:"PloDescription"},
                    { text: "T. Corte Acon.", value:"TypeCutNameAcondition", width: 60},
                    { text: "T. Corte", value:"TypeCutName", width: 60},
                    { text: "ID Trazabilidad", value:"TraceabilityID", width: 70},
                    { text: "Empaque", value:"TypePackingName"},
                    { text: "Peso Bolsa", value:"BagWeight"},
                    { text: "Bolsa por caja", value:"BoxBag"},
                    { text: "Tipo Produccion", value:"TypeProduction", width: 90},
                    { text: "Variante", value:"TypeVariant", width: 90},
                    { text: "E. Final", value:"StateFinal", width: 90},
                    { text: "Observacion", value:"Observation"},
                ],
                TotalBags: 0,
                TotalBagWeight: 0,
                TotalBox: 0,
                CantPorCaja: 0,
                ListVariante: [],
                ListEstadoFinal: [],
                TypeProduction: 1,
            }
        },
        methods: {
            initialize(){
                _sendsealedbags.list({PlpID : this.SelectLine.PlpID}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        r.data.forEach((element, index) => {
                            element.Line = index + 1;
                            // this.TotalBags = this.TotalBags + element.countsealedbags;
                            // this.TotalBagWeight = this.TotalBagWeight + parseFloat(element.BagWeight) * element.countsealedbags;
                            // this.CantPorCaja = parseInt(element.BoxBag);
                        });
                        this.itemsBagSealedDetail = r.data;  
                        console.log("this.itemsBagSealedDetail", this.itemsBagSealedDetail); 
                        // this.TotalBox = this.TotalBags / this.CantPorCaja;
                        // console.log("this.TotalBags->", this.TotalBags,' ', this.TotalBagWeight);            
                    }
                })
            },
            close(){
                this.$emit("close");
            },
            btnSaveDetail(){
                
                this.Calcular();
                let ArmedBox = {};
                ArmedBox.TotalBags = this.TotalBags;
                ArmedBox.TotalBagWeight = this.TotalBagWeight;
                ArmedBox.CantPorCaja = this.CantPorCaja;
                ArmedBox.TotalBox = this.TotalBox;
                ArmedBox.PlpID = this.SelectLine.PlpID;                
                ArmedBox.boxDetails = this.selectedDetail;
                ArmedBox.isRepacking = this.selectedDetail[0].isRepacking;
                ArmedBox.CumID = this.selectedDetail[0].CumID;
                
                if(this.selectedDetail[0].StateFinal == null)
                {
                    this.$fun.alert("Debe Selecionar Estado Final", "warning");
                    return;
                }
                // return
                if(this.selectedDetail.length == 0)
                {
                    this.$fun.alert("Debe Selecionar Bolsas a empaquetar", "warning");
                    return;
                }
             
                let bandera = true;
                let bagsend = 0;
                this.selectedDetail.forEach(element => {
                        if(element.bagutilizadas > element.countsealedbags || element.bagutilizadas  == 0)    
                        {
                            bandera = false;
                            bagsend = element.countsealedbags;
                        }
                })
                if(bandera)
                {                               
                    _sFrzPackingArmedBox.save(ArmedBox, this.$fun.getUserID())
                    .then( r => {
                        if(r.status == 200)
                        {
                            this.$fun.alert("Se registro correctamente", "success");
                            this.selectedDetail = [];
                            this.initialize();
                        }
                    });
                }else{
                    this.$fun.alert("Las bolsas a enviar deben ser menor o igual que "+ bagsend + " o mayor 0", "warning");
                }
            },
            Calcular(){                
                let detail = [];
                detail = this.selectedDetail;
                
                this.TotalBagWeight = 0;
                this.TotalBags = 0;
                this.TotalBox = 0;
                detail.forEach(element => {
                    this.TotalBags = this.TotalBags + parseFloat(element.bagutilizadas);
                    this.TotalBagWeight = this.TotalBagWeight + parseFloat(element.BagWeight) * parseFloat(element.bagutilizadas);
                    this.CantPorCaja = parseInt(element.BoxBag);
                })      
                if(this.CantPorCaja > 0)                 
                    this.TotalBox = parseFloat(this.TotalBags / this.CantPorCaja);
            },
            changeTypeProduction(item)
            {             
                
                this.TypeProduction = item;
                this.ListVariante = [];
                this.ListEstadoFinal = [];
                this.GetListVariante();
                this.GetListEstadoFinal();
          
            },
            GetListVariante(){
                _typestatefinal.list({DedID : this.TypeProduction, Position : 1}, this.$fun.getUserID()).then( r => {
                    if(r.status == 200)
                    {
                        this.ListVariante = r.data;
                        this.ListVariante.forEach((element, index) => {
                            element.ID = index + 1;
                        });
                       
                    }
                })
            },
            GetListEstadoFinal(){
                _typestatefinal.list({DedID : this.TypeProduction, Position : 2}, this.$fun.getUserID()).then( r => {
                    if(r.status == 200)
                    {
                        this.ListEstadoFinal = r.data;
                        this.ListEstadoFinal.forEach((element, index) => {
                            element.ID = index + 1;
                        });
                        
                    }
                })
            },
        },
        created(){
            this.initialize();
            console.log(this.SelectLine);
        }
    }
</script>