<template>
	<div>
		<v-card
			dense
			outlined
			
		>
			<v-card-title>
				Pausar Linea
			</v-card-title>
			<v-card-text >
				<v-row>
					<v-col class="s-col-form">
						<s-date readonly v-model="itemPause.LppDate"> </s-date>
					</v-col>
					<v-col class="s-col-form">
						<s-select-definition
							label="Tipo Pausa"
							:def="1432"
							v-model="itemPause.TypeCategory"
						></s-select-definition>
					</v-col>
				</v-row>

				<v-row>
					<v-col class="s-col-form">
						<s-text
							label="Responsable"
							v-model="itemPause.LppResponsible"
						></s-text>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="s-col-form">
						<s-textarea
							label="Descripción"
							v-model="itemPause.LppDescription"
						></s-textarea>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-btn
					color="success"
					text
					@click="savePause()"
				>
					Guardar
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn
					color="orange"
					text
					@click="closeDialogStop()"
				>
					Cancelar
				</v-btn>
			</v-card-actions>
		</v-card>
		
	</div>
</template>

<script>
	import _sPause from "@/services/FrozenProduction/FrzCtnLinesPackingPauseProcess.js"

	export default {
		props: {
			lineSelected: {},
		},

		data() {
			return {
				itemPause:{},
				
			}
		},

		methods: {
			closeDialogStop() {
				this.$emit("closeDialogStop");
			},

			savePause()
			{
				console.log('linea a Pausar',this.lineSelected.PlpID)
				this.$fun.alert("¿Seguro de pausar?", "question")
				.then(r =>{
					if(r.value)
					{
						this.itemPause.LppID = 0;
						this.itemPause.PlpID = this.lineSelected.PlpID;
						this.itemPause.SecStatus = 1;
						this.itemPause.UsrCreateID = this.$fun.getUserID();
						this.itemPause.UsrUpdateID = this.$fun.getUserID();

						_sPause
						.save(this.itemPause, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Pausado correctamente", "success");
								this.closeDialogStop();
								return;
							}
						})
					}
				})
			},

			
		},
		mounted(){
			this.itemPause.LppHourBegin = this.$fun.formatTimeShortView(this.$moment().format("hh:mm"));
		}

	}
</script>

<style scoped>

</style>