import Service from "../Service";

const resource = "FrzPackingLinesGroup/";

export default {
    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },
 
    listdetail(prd, requestID) {
        return Service.post(resource + "list", prd, {
            params: { requestID: requestID },
        });
    },
    saveStatus(prd, requestID) {
        return Service.post(resource + "saveStatus", prd, {
            params: { requestID: requestID },
        });
    },

}