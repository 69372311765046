<template>
    <div>
        <v-badge
            bordered
            color="error"
            icon="mdi-lock"
            overlap
            v-if="TypeAction == 1"
        >
        
        <v-chip
            small
            color="info"   
        >
        {{line.PloDescription}}
        </v-chip>
        </v-badge>

        <v-chip
            small
            color="info"   
            v-else="TypeAction == 2"
        >
        <!-- <v-icon v-if="LstEndProcees.PlpID"></v-icon> -->
        <v-icon v-if="!LstEndProcees.PlpID" left x-small bordered style="cursor:pointer;" @click="finallyLinePackingProcess()">
            fa-door-open >
            dh
        </v-icon> 
        
        {{line.PloDescription}}
            <!-- <v-icon small style="cursor:pointer; margin:0px 0px 0px 5px" @click="configLinePacking()"> fas fa-cog </v-icon> -->
            <v-menu
                bottom
                left
                
            >
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                    x-small
                    class="white--text"
                    
                >
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
                </template>

                <v-list>
                <v-list-item
                    
                    v-for="(itemL, i) in itemsL"
                    :key="i"
                    @click="openOptions(itemL,i)"
                >
                    <v-list-item-title>{{ itemL.title }}</v-list-item-title>
                </v-list-item>
                </v-list>
            </v-menu>
        </v-chip>
   
        <div class="containerLine">
            <div class="lineSide"></div> <!-- LADO DE LA LINEA -->

            <div class="grafLine" v-if="!LstEndProcees.PlpID">
                <br>
                <template>
                    <v-btn
                        fab
                        color="orange"
                        x-small
                        :color="TypeAction == 1 ? 'success' : 'warning'"
                        @click="openDialogPause(line)"
                    >
                    <!--   -->
                        <v-icon v-if="TypeAction == 2 || TypeAction == 0" color="white">far fa-pause-circle</v-icon>
                        <v-icon v-if="TypeAction == 1">far fa-play-circle</v-icon>
                    </v-btn>
                </template>

                
                <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
                
            </div> <!-- LINEA -->
            <div class="grafLineEndProcess textLineVertical" v-else="LstEndProcees.PlpID">
                Proceso Finalizado
            </div>

            <div class="lineSide"></div> <!-- LADO DE LA LINEA -->
        </div>
        <!-- MODALES -->
        <!-- -------------------- -->
        <!-- MODAL PARA ASIGNAR PERSONAS A LINEA -->
        <v-dialog
            v-if="modalAsignPrsLine"
            v-model="modalAsignPrsLine"
            persistent
            width="850"
        >
            <v-card>
                <s-toolbar     
                    label="Asignar personas a la linea"
                    dark
                    close
                    @close="closeDialog()"
                    save 
                    @save="save()"
                    color="primary"
                    v-if="!LstEndProcees.PlpID"
                >
                </s-toolbar>
                <s-toolbar     
                    label="Asignar personas a la linea"
                    dark
                    close
                    @close="closeDialog()"
                   
                    color="primary"
                    v-else="LstEndProcees.PlpID"
                >
                </s-toolbar>
                <v-container>
                    <v-row>
                        <v-col cols="6" lg="6" md="6" sm="12">
                            <h2> {{line.PloDescription}}  : </h2> 
                        </v-col>   
                    </v-row>
                    <v-row rows>
                        <v-col cols="12" lg="12" md="12" sm="12">
                            <div v-if="$fun.isAdmin()">
                                
                                <v-row class="pt-3 pr-3 pl-3">
                                    <h4>Buscar Personal</h4>
                                    <v-spacer></v-spacer>
                                    <v-btn v-if="!LstEndProcees.PlpID" rounded fab x-small @click="addItemGP()"
                                        ><i class="fas fa-plus"></i
                                    ></v-btn>
                                    &nbsp;&nbsp;
                                    <v-btn
                                        fab
                                        x-small
                                        color="warning"
                                        @click="saveActivation()"
                                        v-if="!LstEndProcees.PlpID"
                                    >
                                        <v-icon>fa-unlock</v-icon>

                                        <!-- <font-awesome-icon :icon="['fas', 'right-to-bracket']" /> -->
                                    </v-btn>
                                </v-row>
                                <v-divider></v-divider><br>
                                <v-row >                           
                                    <v-col cols="6" lg="6" md="6" sm="12" >		
                                        <s-toolbar-person
                                            v-model="line.PrsDocumentNumber"
                                            @returnPerson="hadReturnPerson($event)"
                                            ref="focusPrsDocument"
                                            noCamera
                                            noEdit
                                        />					
                                    </v-col>
                                    <v-col cols="6" lg="6" md="6" sm="12">
                                        <s-text
                                            label="Nombres"
                                            v-model.trim="newPerson.PlgName"
                                            ref="focusPlgName"
                                        ></s-text>
                                    </v-col>
                                    <v-col cols="6" lg="6" md="6" sm="12">
                                        <s-text
                                            label="Apellidos"
                                            v-model.trim="newPerson.PlgLastName"
                                        ></s-text>
                                    </v-col>
                                    <v-col cols="6" lg="6" md="6" sm="12">
                                        <s-text
                                            label="DNI"
                                            v-model.trim="newPerson.PrsDocumentNumber"
                                        ></s-text>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pl-0 pt-0">
                                        <v-data-table
                                            v-model="selected"
                                            item-key="PlgID"
                                            dense
                                            :headers="headerLinePacking"
                                            :items="itemsDataPG"
                                            hide-default-footer
                                            :items-per-page="-1"
                                            disable-sort
                                            show-select
                                        >
                                            <template v-slot:item.Actions="{ item }">
                                                <v-btn
                                                    @click="clickDeleteItemGP(item)"
                                                    x-small                                        
                                                    fab
                                                    rounded
                                                    color="error"
                                                    v-if="!LstEndProcees.PlpID"
                                                >
                                                <v-icon style="font-size: 16px !important"
                                                >fa-solid fa-trash</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>   
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>

        <!-- MODAL PARA PAUSAS POR LINEA -->
        <v-dialog
            v-if="dialogPause"
            v-model="dialogPause"
            persistent
            width="500"
        >
            <pause-line
            @closeDialogStop="closeDialogStop()"
            :lineSelected="lineSelected"
            ></pause-line>
        </v-dialog>
        <!-- FIN MODALES -->
    </div>
</template>


<script>
    import('../../../../styles/linesPacking.css');
    import SToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
    import _sFrzPackingLinesGroupService from "@/services/FrozenProduction/FrzPackingLinesGroupService.js";
    import _sFrzPackingLinesL from "@/services/FrozenProduction/FrzLinesPackingL.js";

    import _sPause from "@/services/FrozenProduction/FrzCtnLinesPackingPauseProcess.js"

    import PauseLine from './CPauseLinePacking.vue';
  
  export default {
    components: { SToolbarPerson,PauseLine},
    props: {
        line: {
            type: Object,
            required: true,
        },
        processInitial: {},
    },
    data: () => ({
        TypeAction: 0,
        PlgStatus:"",
        modalAsignPrsLine:false,
        dialogPause: false,
        newPerson: {},
        itemsDataPG:[],
        selected: [],
        lineSelected:{},
 
        headerLinePacking: "",
        headerLinePacking: [
            { text:"item", value :"Line"},
            { text: "Nombre", value: "PlgName"},
            { text: "Apellidos", value: "PlgLastName"},
            { text: "dni", value: "PrsDocumentNumber"},
            { text: "Estado", value: "PlgStatusN" },
            { text: "", value: "Actions" },
        ],
        itemsDeletePG:[],
        itemsL:"",
        itemsL: [
            { title: 'Agregar Personal a Linea ',value:"AddPersonLine" },
            // { title: 'Pausar Linea',value:"PauseLine"  },
            // { title: 'etc',value:"etc"  },
        
        ],
        lastSave: {},
        LstEndProcees:{},
        
    }),
    
    methods: {
        openOptions(item,i){
            console.log('Opciones de Lineas',item,i);
            if(i == 0){
                this.modalAsignPrsLine = true
            }
            if(i == 1){
               
                // console.log('TypeAction',this.TypeAction)
            }
            if(i == 2){
                // this.clickUrlPermissions ()
            }
            

        },
        // configLinePacking(){
        //     console.log('configurar lineas');
        //     this.modalAsignPrsLine = true
        //     this.listPerson();
        // },
        closeDialogStop()
        {
            this.dialogPause = false;
            this.getLastPauseByLine();
        },
        closeDialog(){
            this.modalAsignPrsLine = false;
        },
        hadReturnPerson(line){
            console.log("datos DNI",line)
            //console.log("props",props)
            if (line != null) {
                this.newPerson.PlgName = line.NtpName.trim();
                this.newPerson.PlgLastName = line.NtpPaternalSurname.trim() +" "+ line.NtpMaternalSurname.trim();
                this.newPerson.PrsDocumentNumber = line.PrsDocumentNumber;
                
                // this.newPerson.PklID = line.PklID
                this.newPerson.PrsID = line.PrsID;
                this.newPerson.NtpID = line.NtpID;
                this.newPerson.CtrID = line.CtrID;

            }
            
        },
        addItemGP(){
            
            let existPerson = false;
            this.itemsDataPG.forEach(element => {
                if(element.PrsDocumentNumber == this.newPerson.PrsDocumentNumber){
                    existPerson = true;
                    console.log('trabajador existe',existPerson)
                }
            });
            if (existPerson){
                this.$fun.alert("Trabajador ya Ingresado", "warning");
                return ;
            }
                if (!this.newPerson.PlgName) {
                this.$refs.focusPlgName.error("Debe ingresar el nombre de un trabajador");
                return;
                } else {

                this.newPerson.Line = this.itemsDataPG.length + 1;
                this.newPerson.SecStatus = 1;
                this.itemsDataPG.push({...this.newPerson});
                //console.log("items agregados",this.newPerson)
                this.newPerson = {};

                this.PlgName = "";

                this.$refs.focusPlgName.focus();
                
                
            }
        },
        clickDeleteItemGP(item) {
            console.log('delete trabajador del grupo de produccion   ', item);
            this.itemsDataPG = this.itemsDataPG.filter(
                (x) => {
                return x.Line != item.Line
                }
            );
            item.SecStatus = 0;
            if(item.PlgID > 0)
            {
                this.itemsDeletePG.push({ ...item} );
                console.log('delete trabajador del grupo de produccion  2 ', item);
            }
             
        },
        save() {
            // console.log('id->',this.line.PklID);
            console.log('sss',this.itemsDataPG);
            /**validar que el grupo tenga integrantes */
            if(this.itemsDataPG.length <= 0 ){
                this.$fun.alert("Grupo debe tener Integrantes", "warning");
                this.$refs.focusPrsDocument.focus();
                return;
            }
            /** fin de validar */

            console.log("eliminados", this.itemsDeletePG)

            this.itemsDataPGAdd = [...this.itemsDataPG, ...this.itemsDeletePG]

            let item = {};
            // this.itemsDataPGAdd = [...this.itemsDataPG, ...this.itemsDeletePG];
            item.FrzPackingLinesGroup = this.itemsDataPGAdd;
            item.FrzPackingLinesGroup.forEach(element => {
                element.PlpID = this.line.PlpID;
                element.UsrCreateID = this.$fun.getUserID();
                element.UsrUpdateID = this.$fun.getUserID();
            });
    
            console.log("a guardar", item);

            _sFrzPackingLinesGroupService
            .save(item, this.$fun.getUserID())
            .then((r) => {
                if (r.status == 200) {
                    this.$fun.alert("Registrado correctamente", "success");
                    this.$emit("close");
                    this.itemsDataPG = []
                    this.listPerson();
                }
            });
            


        },
        listPerson(){
            console.log('listado de integrantes',this.line.PlpID)
            let obj ={
                PlpID : this.line.PlpID,
                PlpdateProcess : this.line.PlpdateProcess,
                TypeTurn : this.line.TypeTurn,
                TypeCultive :this.line.TypeCultive
            }
            console.log('obj',obj)
            _sFrzPackingLinesGroupService.listdetail(obj, this.$fun.getUserID())
            .then((r) => {
                if (r.status == 200) {
                    r.data.forEach((el, index) => {
                        el.Line = index + 1;
                    });

                    console.log("detalles", r.data)

                    this.itemsDataPG = r.data;
                }
                
            })
        },
        saveActivation(){
            if(this.selected.length <= 0){
                // console.log('no seleccion para bloquearf')
                this.$fun.alert("Seleccione Personal", "warning");
                return;
            }else{
                this.$fun.alert("¿Desea Activar / Inactivar?", "question")
                .then((r) => {

                    if (r.value) {
                        
                        let list = [];
                        this.selected.forEach(element => {
                            list.push(element.PlgID);
                        });

                        let obj = {
                            List : list,
                            PlpID: this.line.PlpID,
                            UsrCreateID : this.$fun.getUserID(),
                            UsrUpdateID : this.$fun.getUserID(),
                        }
                       
                        console.log('personas ',obj.List)
                        console.log('id grupo',obj.PlpID)
                        // console.log('id Person',obj.List)
                       

                        this.selected = [];
                        _sFrzPackingLinesGroupService.saveStatus(obj, this.$fun.getUserID())
                        .then((r) => {
                            if (r.status == 200) {
                            this.$fun.alert("Guardado correctamente", "success");
                           
                            this.modalAsignPrsLine = false
                            this.$emit("refreshlines");
                            }
                        })
                        
                    }
                });
            }
        },
        openDialogPause(line)
        {
    
            console.log('typeaction', this.TypeAction);
            if(this.TypeAction == 2 || this.TypeAction == 0)
            {
                this.dialogPause = true   
                this.lineSelected = line;
            }
            else
            {
                this.savePlay();
            }
        },
        getLastPauseByLine()
        {
            if(this.line != null)
            {
                _sPause
                .lastPauseline(this.line, this.$fun.getUserID())
                .then(resp => {
                    if(resp.status == 200)
                    {
                        if(resp.data != null)
                        {
                            this.TypeAction = resp.data.TypeAction;
                            this.lastSave = resp.data;
                        }
                        
                    }
                });
            }
        },
        savePlay(){

            console.log('reanudar')
            this.$fun.alert("¿Seguro de reanudar?", "question")
            .then(r =>{
                if(r.value)
                {
                    this.lastSave.LppID = 0;
                    this.lastSave.LppDate = new Date();
                    // this.lastSave.TypePlace = 1;
                    this.lastSave.TypeAction = 2;
                    this.lastSave.TypeCategory = null;
                    this.lastSave.LppResponsible = null;
                    this.lastSave.LppDescription = null;
                    this.lastSave.SecStatus = 1;
                    this.lastSave.UsrCreateID = this.$fun.getUserID();
                    this.lastSave.UsrUpdateID = this.$fun.getUserID();

                    _sPause
                    .save(this.lastSave, this.$fun.getUserID())
                    .then(resp => {
                        if(resp.status == 200)
                        {
                            this.$fun.alert("Reanudado correctamente", "success");
                            this.getLastPauseByLine();
                            return;
                        }
                    })
                }
            })
		},
        finallyLinePackingProcess(){
            console.log('finalizar proceso de linea',this.line.PlpID);

            this.$fun.alert("¿Esta seguro de finalizar Proceso?", "question")
                .then((r) => {
                    if (r.value) {
                        let obj = {
                            PlpID : this.line.PlpID,
                            UsrUpdateID : this.$fun.getUserID(),
                        }

                        _sFrzPackingLinesL.linesEnd(obj, this.$fun.getUserID())
                        .then((r) => {
                            if (r.status == 200) {
                                this.$fun.alert("Proceso Terminado Correctamente", "success");
                                this.$emit("refreshlines");
                            }
                        })
                        
                    }
                });

        },
        linesEndProcess(){
           
            _sFrzPackingLinesL.linesEndProcess({PlpID : this.line.PlpID}, this.$fun.getUserID())
            .then((r) => {
                if (r.status == 200) {
                    if(r.data != null)
                    {
                        this.LstEndProcees = r.data;
                        console.log('lineas finalizadas',this.LstEndProcees.PlpID)
                    }
                }
            })
        },

        


                    
        
    },
    created () {
        this.listPerson();
        this.getLastPauseByLine();
        this.linesEndProcess();
	},
    mounted(){
        
        
    }

  };

</script>