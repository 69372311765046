<template>
    <div>        
        <v-card>
            <s-toolbar label="Asistencia y Rol del personal"
                    dark 
                    close 
                    @close="Close()"
                    color="primary">
                </s-toolbar>
            <v-card-title>
                <v-row>
                    <v-col>
                        <h3></h3>
                    </v-col> 
                    <v-col cols="auto">
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-btn 
                                    fab 
                                    x-small 
                                    color="success"
                                    v-on="on"
                                    @click="TransferPerson()"
                                    >
                                    <v-icon class="mdi-24px ">mdi-transit-transfer</v-icon>
                                </v-btn>
                                
                            </template>
                            <span>Tranferencia de personal</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="auto">
                        
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">                            
                                <v-btn 
                                    fab 
                                    x-small 
                                    color="primary"
                                    v-on="on"
                                    @click="SaveAssistance()"
                                    >
                                    <v-icon class="mdi-24px ">mdi-timer</v-icon>
                                </v-btn>
                            </template>
                            <span>Asistencia y Rol</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-card-title>
            <br>
            <v-card-text>
                <v-data-table
                        v-model="selectedGroup"
                        dense
                        :headers="headerPackingGroup"
                        :items="ListGroupPerson"
                        :items-per-page="-1"                        
                        disable-sort                        
                        hide-default-footer
                        item-key="PgdID"
                        show-select
                        class="elevation-1"
                        >

                        <template v-slot:item.LpmHourInitial="{ item }"> 
                            <div class="reloj">
                                    <!-- <v-icon small color="warning">fa-clock</v-icon>  -->
                                <datetime valueZone="America/Lima"  type="time" v-model="item.LpmHourInitial">
                                    
                                </datetime>
                            </div>
                                    <!-- {{item.LpmHourInitial}} -->
                        </template>

                        <template v-slot:item.TypeRoll="{ item }"> 
                            <!-- <s-select-definition
                                :def="1430"
                                v-model="item.TypeRoll"
                                clearable
                            ></s-select-definition> -->
                            <s-select
                                :items="itemRol"
                                item-value="SrdID"
                                item-text="SrdDescription"
                                v-model="item.TypeRoll"
                            >
                            </s-select>
                        </template>
                      
                    </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog v-model="DialogTransferPerson"
            v-if="DialogTransferPerson" width="500">
                <v-card>
                    <s-toolbar 
                        dark
                        color="primary" 
                        close 
                        @close="DialogTransferPerson = false" 
                        label="Transferencia de personal">

                    </s-toolbar>
                    <v-card-title>
                        <div class="text-h6 font-weight-bold mb-4">Lineas en Proceso</div>
                    </v-card-title>
                    <v-card-text>
                        <v-item-group  v-model="selectTranferLines">                            
                            <v-container>
                                <v-row justify="center">
                                    <v-col 
                                        class="fm-card-container" 
                                        cols="auto"
                                        v-for="item in linesOutProcess" 
                                        v-bind:key="item.PlpID"
                                        :value="item"
                                    >
                                        <v-item
                                            v-slot="{ active ,toggle}">
                                            <v-chip 
                                                
                                                :color="active ? 'success' : ''" @click="toggle">
                                                    {{ item.PloDescription }}
                                            </v-chip>
                                        </v-item>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-item-group>
                    </v-card-text>
                    <v-card-actions >
                        <div class="pa-4 text-end">
                            <v-btn 
                                block 
                                rounded
                                color="primary"
                                depressed
                                @click="SaveTranfer()">
                                <v-icon left>mdi-content-save</v-icon>
                                GUARDAR
                            </v-btn>
                        </div>
                        
                    </v-card-actions>
                </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import { Datetime } from 'vue-datetime';
    import _PackingGroup from "@/services/FrozenProduction/FrzPackingGroupService.js"; 
    import _sServiceAssistance from "@/services/FrozenProduction/FrzPackingAssistanceLineOutProcess.js"
    import _sFrzPackingWorkStationRolesService from "@/services/FrozenProduction/FrzPackingWorkStationRoles.js" 
    
    export default {
        components:{
            Datetime
        },
        props:{
            SelectLine: {
                default: null,
                type: Object,
            },
            TrnID:{
                default: null,
                type: Number,
            },
            linesOutProcess: {
                default: null,
                type: Array,
            },
        },
        data(){
            return {
                selectedGroup: [],
                ListGroupPerson:[],
                headerPackingGroup: [
                    { text:"item", value :"Line"},
                    { text: "Nombre", value: "PgdName"},
                    { text: "Apellidos", value: "PgdLastName"},
                    { text: "DNI", value: "PrsDocumentNumber"},
                    { text: "Hora de ingreso ", value: "LpmHourInitial", width: "150"},
                    { text: "Rol", value: "TypeRoll", width: "200" },
                    // { text: "Guardar Rol", value: "saveRoll", align: "center"},


                ], 
                DialogTransferPerson: false,  
                selectTranferLines: [],
                itemRol : [],
                // linesInProcess: [],
            }
        },
        watch:{
            SelectLine: function(){
                console.log(this.SelectLine);
            }
        },
        methods:{
            getListGroupPerson()
            {
                let group = {}
                group.PggID = this.SelectLine.PggID;
                group.PlpID = this.SelectLine.PlpID;
                _PackingGroup.listdetaillinesout(group, this.$fun.getUserID()).then( r => {
                
                    if(r.status == 200){
                        r.data.forEach((element, index) => {
                            element.Line = index + 1;
                            element.LpmHourInitial = this.$fun.formatTimeShortView(this.$moment(element.HourInitial).format("HH:mm"));
                        })
                        console.log(r.data);
                        this.ListGroupPerson = r.data;
                        
                    }
                })
            },
            SaveAssistance(){
                if(this.selectedGroup.length <= 0)
                {
                    this.$fun.alert("Selecciones personal", "warning");
                    return;
                }

                this.selectedGroup.forEach(element => {
                    element.UsrID = this.$fun.getUserID();
                    element.UsrUpdate = this.$fun.getUserID();
                    element.TrnID = this.SelectLine.TrnID;
                    element.PlpID = this.SelectLine.PlpID;
                });
                console.log(this.selectedGroup);
                this.$fun.alert("¿ Desea guardar asistencia?", "question")
                .then((r) => {
                    if(r.value)
                    {
                        _sServiceAssistance.saveassistance(this.selectedGroup, this.$fun.getUserID()).then((r) => {
                            this.$fun.alert("Se registro correctamente", "success");
                            this.getListGroupPerson();
                           
                        });
                    }
                });
            },
            Close(){
                this.$emit("close");
            },
            TransferPerson(){
                this.DialogTransferPerson = true;
            },
            SaveTranfer(){               

                let lineSelect = {};
                lineSelect = this.linesOutProcess[this.selectTranferLines];
               
                if(this.SelectLine.PlpID  == lineSelect.PlpID){
                    this.$fun.alert("No se permite transferir a la misma linea", "info");
                    return;
                }

                this.selectedGroup.forEach(element => {
                    element.UsrID = this.$fun.getUserID();
                    element.UsrUpdate = this.$fun.getUserID();
                    element.TrnID = this.SelectLine.TrnID;
                    element.PlpID = lineSelect.PlpID;
                    element.isTransfer = 1;
                    element.PggID = lineSelect.PggID;
                });
                console.log(this.selectedGroup);
                _sServiceAssistance.savetransfer(this.selectedGroup, this.$fun.getUserID()).then((r) => {
                    this.$fun.alert("Se registro correctamente", "success");
                    this.getListGroupPerson();
                    this.DialogTransferPerson = false;
                });

            },
            dataRol(){
                let WsrID =4 ;
                _sFrzPackingWorkStationRolesService.listDetailRol({WsrID: WsrID}, this.$fun.getUserID()).then( r => {
                
                if(r.status == 200){
                   
                    this.itemRol = r.data;
                    console.log('dat rol',this.itemRol);
                    
                }
            })
            },
            
        },
        mounted(){
            this.getListGroupPerson();
        },
        created(){
            this.dataRol();
        }
    }
</script>
<style>
.reloj {
	width:100px;
	height: auto;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(160, 159, 159);
	border-radius: 4px;
	padding: 3px;
  }
  </style>