import Service from "../Service";

const resource = "FrzPackingResponsibleLines/";

export default {
    saveResponsablesLine(item,requestID) {
        return Service.post(resource + "saveResponsablesLine", item, {
            params: { requestID: requestID },
        });
    },
    listPersonLine(item, requestID) {
        return Service.post(resource + "listPersonLine", item, {
            params: { requestID: requestID },
        });
    },
    saveMtvPermisions(item, requestID) {
        return Service.post(resource + "saveMtvPermisions", item, {
            params: { requestID: requestID },
        });
    },    
    lisMtvAsig(item, requestID) {
        return Service.post(resource + "lisMtvAsig", item, {
            params: { requestID: requestID },
        });
    },     
    dltPermisoMtv(item, requestID) {
        return Service.post(resource + "dltPermisoMtv", item, {
            params: { requestID: requestID },
        });
    }, 
    

}